// @flow

import React, { useState, useEffect } from 'react';
import { ChakraProvider, Box, Spinner, VStack } from '@chakra-ui/react';

import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { getUserInfo } from './utils/apiWrapper';
import Admin from './pages/Admin';
import theme from './Theme';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const resp = await getUserInfo();

      setLoading(false);
      if (resp.status === 200) {
        setUser(resp.data.result);
      }
    };

    fetchUserInfo();
  }, [setUser]);
  return (
    <ChakraProvider theme={theme}>
      <Box>
        <VStack minH="100vh">
          <ColorModeSwitcher justifySelf="flex-end" alignSelf='flex-end'/>
          {loading ? (
            <Box display="flex" justifyContent="center">
              <Spinner size="xl" />
            </Box>
          ) : user ? ( user.admin ? <Admin user={user}/> :
            <Home user={user} setUser={setUser} />
          ) : (
            <Login setUser={setUser} />
          )}
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default App;
