import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Text,
  Skeleton,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Tr,
  Td,
  Thead,
  Tbody,
  Th,
  Code,
  HStack,
  Icon,
  VStack,
} from '@chakra-ui/react';

import { IoIosFlash } from 'react-icons/io';

import { ToWords } from 'to-words';

import {
  getGameState,
  registerUser,
  registerAdmin,
  getPlayerState,
  getLeaderboard,
  assassinateTarget,
} from '../utils/apiWrapper';
import '../css/Home.css';

export const Home = ({ user, setUser }) => {
  const [gameState, setGameState] = useState(null);
  const [leaderboard, setLeaderboard] = useState(null);
  const [playerState, setPlayerState] = useState(null);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerAdminLoading, setRegisterAdminLoading] = useState(false);
  const [assassinateLoading, setAssassinateLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [asssassinateError, setAssassinateError] = useState(false);
  const [assassinKey, setAssassinKey] = React.useState('');

  const handleInputChange = event => setAssassinKey(event.target.value);

  window.history.replaceState({}, document.title, '/');

  useEffect(() => {
    const fetchLeaderboard = async () => {
      const resp = await getLeaderboard();
      if (resp.status === 200) {
        setLeaderboard(resp.data.result);
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    const fetchGameState = async () => {
      const resp = await getGameState();

      if (resp.status !== 200) setShowError(true);
      else {
        setShowError(false);
        setGameState(resp.data.result);
      }
    };
    fetchGameState();
  }, [setGameState]);

  useEffect(() => {
    const fetchPlayerState = async () => {
      const resp = await getPlayerState();

      if (resp.status !== 200) setShowError(true);
      else {
        setShowError(false);
        setPlayerState(resp.data.result);
      }
    };

    fetchPlayerState();
  }, [gameState, setPlayerState]);

  const registerUserHandle = async () => {
    setRegisterLoading(true);

    const resp = await registerUser();
    if (resp.status !== 200) setShowError(true);
    else {
      setShowError(false);
      setUser({ ...user, registered: true });
      setPlayerState(resp.data.result.player);
      setGameState(resp.data.result.game);
    }

    setRegisterLoading(false);
  };

  const toWords = new ToWords();

  const registerAdminHandle = async () => {
    setRegisterAdminLoading(true);

    const resp = await registerAdmin();
    if (resp.status !== 200) setShowError(true);
    else {
      setShowError(false);
      setUser({ ...user, admin: true });
      setGameState(resp.data.result.game);
    }
  };

  const handleAssassinate = async () => {
    setAssassinateLoading(true);

    const resp = await assassinateTarget(assassinKey);
    if (resp.status !== 200) setAssassinateError(true);
    else {
      setAssassinateError(false);
      setPlayerState(resp.data.result.player);
      setGameState(resp.data.result.game);
    }

    setAssassinateLoading(false);
  };

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 17) return 'Good afternoon';
    return 'Good evening';
  };

  const renderGreeting = () => {
    if (!gameState || !gameState.active) {
      return (
        <Heading marginBottom="8">
          Welcome to Assassins, {user.firstName}
        </Heading>
      );
    } else if (gameState.active) {
      return (
        <VStack alignItems="flex-start" marginBottom={8}>
          <Heading size="2xl" marginBottom={2}>
            {getGreeting()}, {user.firstName}
          </Heading>
          <Heading size="lg" fontWeight="semibold">
            Welcome back to Assassins
          </Heading>
        </VStack>
      );
    }
    return (
      <Heading marginBottom="8">Welcome to Assassins, {user.firstName}</Heading>
    );
  };

  const renderGameState = () => {
    if (gameState.winner) {
      return (
        <Text fontSize="lg">
          Congratulations, {gameState.winner} for winning this semester's
          assassins!
        </Text>
      );
    } else if (!user.registered) {
      if (!user.registered && gameState.active) {
        return <Text>The registration period has passed</Text>;
      }
      return (
        <ButtonGroup spacing="4">
          <Button
            isLoading={registerLoading}
            loadingText="Registering"
            colorScheme="blue"
            onClick={registerUserHandle}
          >
            Register
          </Button>
          {user.level === 'ADMIN' && (
            <Button
              isLoading={registerAdminLoading}
              loadingText="Registering"
              colorScheme="blue"
              onClick={registerAdminHandle}
            >
              Register as admin
            </Button>
          )}
        </ButtonGroup>
      );
    } else if (!gameState.active) {
      return (
        <>
          <Text fontSize="lg">
            You're registered! Please wait for the game to begin.
          </Text>
        </>
      );
    } else if (!playerState?.alive) {
      return playerState ? (
        <Text fontSize="lg">You were eliminated by {playerState.killer}</Text>
      ) : (
        <Text fontSize="lg">You were eliminated :(</Text>
      );
    } else {
      return (
        <>
          <Text fontSize="2xl">Your target: {playerState?.target?.name}</Text>
          <InputGroup size="md" width="100%" maxWidth="500px" marginTop={2}>
            <Input
              isInvalid={asssassinateError}
              value={assassinKey}
              onChange={handleInputChange}
              pr="4.5rem"
              placeholder={`Enter ${playerState?.target?.name}'s key`}
            />
            <InputRightElement width="4.5rem" paddingRight={2}>
              <Button
                h="1.75rem"
                size="sm"
                onClick={handleAssassinate}
                isLoading={assassinateLoading}
              >
                Submit
              </Button>
            </InputRightElement>
          </InputGroup>
          <Text fontSize="lg" marginTop={2}>
            Your assassin will need this key if they eliminate you:{' '}
            <Code>{playerState?.key}</Code>
          </Text>
          <br />
          <Text fontSize="2xl">
            You've eliminated{' '}
            {toWords.convert(playerState?.kills).toLowerCase()}{' '}
            {playerState?.kills !== 1 ? 'players' : 'player'} so far.
          </Text>
          {playerState?.message && playerState.message !== '' && (
            <Text marginTop="2" fontSize="lg">
              {' '}
              {playerState.message}
            </Text>
          )}
        </>
      );
    }
  };

  return (
    <Box
      paddingLeft={['2%', '5%', '10%']}
      paddingRight={['2%', '5%', '10%']}
      width="100%"
      display="flex"
      flexDirection="column"
      // className="page"
    >
      {renderGreeting()}
      <Box marginBottom="8" className="content-box">
        {gameState ? (
          renderGameState()
        ) : (
          <Skeleton height="60px" width="100%" maxWidth="500px" />
        )}
        {showError && (
          <Alert status="error" marginTop="4" width="100%">
            <AlertIcon />
            <AlertDescription>Error with game state.</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setShowError(false)}
            />
          </Alert>
        )}
      </Box>
      {leaderboard && (
        <Box className="content-box">
          <Heading marginBottom="4">Leaderboard</Heading>
          <Table size="md" fontSize="18" maxWidth="600">
            <Thead>
              <Tr>
                <Th fontSize="16" width="80%">
                  Name
                </Th>
                <Th fontSize="16" width="10%">
                  Stuns
                </Th>
                <Th fontSize="16" width="10%" isNumeric>
                  Kills
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {leaderboard.map(
                player =>
                  player.alive && (
                    <Tr key={player._id}>
                      <Td>
                        <Text
                          as={!player.alive ? 's' : ''}
                        >{`${player.firstName} ${player.lastName}`}</Text>
                      </Td>
                      <Td>
                        <HStack justifyContent="center">
                          {Array.from(
                            { length: player.freezes },
                            (_, index) => (
                              <Icon key={index} as={IoIosFlash} />
                            )
                          )}
                        </HStack>
                      </Td>
                      <Td isNumeric>{`${player.kills}`}</Td>
                    </Tr>
                  )
              )}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};
