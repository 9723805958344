import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Heading,
  Button,
  Text,
  HStack,
  Switch,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Card } from '../components/Card';
import ConfirmEliminateModal from '../components/ConfirmEliminateModal';
import {
  editFreeze,
  endGame,
  getGameState,
  getPlayers,
  startGame,
} from '../utils/apiWrapper';
import EditMessageModal from '../components/EditMessageModal';

// TODO: Add sorting for status: https://chakra-ui.com/getting-started/with-react-table
const Admin = ({ user }) => {
  const [game, setGame] = useState();
  const [players, setPlayers] = useState([]);
  const [currPlayer, setPlayer] = useState(null);
  const [showError, setShowError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [safety1, setSafety1] = useState(false);
  const [safety2, setSafety2] = useState(false);
  const {
    isOpen: isEliminateConfirmationOpen,
    onOpen: onEliminateConfirmationOpen,
    onClose: onEliminateConfirmationClose,
  } = useDisclosure();

  const {
    isOpen: isMessageOpen,
    onOpen: onMessageOpen,
    onClose: onMessageClose,
  } = useDisclosure();

  const [startGameLoading, setStartGameLoading] = useState(false);

  const onEliminateConfirmationCloseAndRefresh = () => {
    onEliminateConfirmationClose();
    setRefresh(refresh + 1)
  }
  const onMessageCloseAndRefresh = () => {
    onMessageClose();
    setRefresh(refresh + 1)
  }

  useEffect(() => {
    const getGame = async () => {
      const resp = await getGameState();
      if (resp.status === 200) {
        setGame(resp.data.result);
      }
    };
    getGame();
  }, []);
  useEffect(() => {
    const getPlayerList = async () => {
      const resp = await getPlayers();
      if (resp.status === 200) {
        setPlayers(resp.data.result);
      }
    };
    getPlayerList();
  }, [game, refresh]);

  const handleEliminateConfirmation = player => {
    setPlayer(player);
    onEliminateConfirmationOpen();
  };

  const handleEditText = player => {
    setPlayer(player);
    onMessageOpen();
  };
  const handleEditStun = async (playerId, newCount) => {
    const resp = await editFreeze(playerId, newCount);
    if (resp.status !== 200) {
      setShowError(true);
    } else {
      setShowError(false);
      setRefresh(refresh + 1);
    }
  };
  const startGameHandle = async () => {
    setStartGameLoading(true);

    const resp = await startGame();
    if (resp.status !== 200) setShowError(true);
    else {
      setShowError(false);
    }

    setStartGameLoading(false);
  };
  const endGameHandle = async () => {
    console.log('here');
    const resp = await endGame();
    if (resp.status !== 200) setShowError(true);
    else {
      setShowError(false);
    }
  };

  return (
    <Card
      w="80%"
      marginX="auto"
      boxShadow="md"
      border="1px"
      borderColor="lightgray"
    >
      <Heading marginX="auto" padding={5} size="lg">
        Welcome to Assassins, {user.firstName}
      </Heading>
      {game?.active === false && (
        <HStack width="100%" justifyContent="space-between" px={8}>
          <Text sz="lg" fontWeight="semibold">
            The game is not active
          </Text>
          <Button
            marginTop={4}
            isLoading={startGameLoading}
            loadingText="Starting game"
            colorScheme="blue"
            onClick={startGameHandle}
          >
            Start game
          </Button>
        </HStack>
      )}
      {game?.active === true && (
        <HStack width="100%" justifyContent="space-between" px={8}>
          <Text sz="lg" fontWeight="semibold">
            The game is active
          </Text>
          <HStack>
            <Switch
              marginTop={4}
              marginRight={4}
              size="lg"
              colorScheme="red"
              isChecked={safety1}
              onChange={() => {
                setSafety1(!safety1);
              }}/>
            <Switch
              marginTop={4}
              marginRight={4}
              size="lg"
              colorScheme="red"
              isChecked={safety2}
              onChange={() => {
                setSafety2(!safety2);
              }}
            />
            <Button
              marginTop={4}
              loadingText="Ending game"
              colorScheme="red"
              isDisabled={!safety1 || !safety2}
              onClick={endGameHandle}
            >
              End game
            </Button>
          </HStack>
        </HStack>
      )}
      {showError && (
        <Alert status="error" marginTop="4" width="100%">
          <AlertIcon />
          <AlertDescription>Error with game state.</AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setShowError(false)}
          />
        </Alert>
      )}
      <TableContainer w="100%" mt="2">
        <Table variant="simple" w="98%" marginX="auto" paddingX="10">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th isNumeric>Kills</Th>
              <Th isNumeric>Stuns</Th>
              <Th>Target</Th>
              <Th isNumeric>Status</Th>
              <Th>Message</Th>
            </Tr>
          </Thead>
          <Tbody>
            {players &&
              players.map((player, idx) => {
                const playerName = `${player.firstName} ${player.lastName}`;
                return (
                  <Tr key={idx}>
                    <Td>{playerName}</Td>
                    <Td isNumeric>{player.kills}</Td>
                    <Td isNumeric>{player.freezes}</Td>
                    <Td>{player.target.name}</Td>
                    <Td isNumeric>
                      {game?.active === false
                        ? ''
                        : player.alive
                        ? 'Alive'
                        : 'Dead'}
                    </Td>
                    {user.level === 'ADMIN' && (
                      <>
                        <Td textAlign="left" width="300px">
                          <Button
                            width="100%"
                            onClick={() => handleEditText(player)}
                          >
                            <Box
                              isTruncated
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                              cursor="pointer"
                              maxWidth='300px'
                            >
                              {player.message}
                            </Box>
                          </Button>
                        </Td>

                        <Td textAlign="right" width="50px">
                          <Button
                            aria-label="Add stun"
                            colorScheme="blue"
                            size="xs"
                            onClick={() =>
                              handleEditStun(player.id, player.freezes + 1)
                            }
                            isDisabled={!player.alive}
                          >
                            Add Stun
                          </Button>
                        </Td>
                        <Td textAlign="right" width="50px">
                          <Button
                            aria-label="Remove stun"
                            colorScheme="blue"
                            onClick={() =>
                              handleEditStun(player.id, player.freezes - 1)
                            }
                            size="xs"
                            isDisabled={!player.alive}
                          >
                            Remove stun
                          </Button>
                        </Td>
                        <Td textAlign="right" width="100px">
                          <Button
                            aria-label="Eliminate"
                            colorScheme="red"
                            onClick={() => handleEliminateConfirmation(player)}
                            isDisabled={!game.active || !player.alive}
                          >
                            Eliminate
                          </Button>
                        </Td>
                      </>
                    )}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <EditMessageModal
        onClose={onMessageCloseAndRefresh}
        isOpen={isMessageOpen}
        player={currPlayer}
        playerName={`${currPlayer?.firstName} ${currPlayer?.lastName}`}
      />
      <ConfirmEliminateModal
        onClose={onEliminateConfirmationCloseAndRefresh}
        isOpen={isEliminateConfirmationOpen}
        player={currPlayer}
        playerName={`${currPlayer?.firstName} ${currPlayer?.lastName}`}
      />
    </Card>
  );
};

export default Admin;
