import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Modal,
  Input,
  Button,
  Divider,
} from '@chakra-ui/react';
import { useState } from 'react';
import { editPlayerMessage } from '../utils/apiWrapper';

const EditMessageModal = ({ onClose, isOpen, player, playerName }) => {
  const [message, setMessage] = useState(player?.message);
  const [isMessageLoading, setMessageLoading] = useState(false);
  const handleChange = event => setMessage(event.target.value);
  const handleEditMessage = async () => {
    setMessageLoading(true);

    const resp = await editPlayerMessage(player.id, message);
    if (resp.status !== 200) {
      setMessageLoading(false);
      onClose();
    } else {
      setMessageLoading(false);
      onClose();
    }
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit {playerName}'s Message</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody display="inherit">
          <Input value={message} onChange={handleChange} margin={2} />
          <Button
            colorScheme="blue"
            onClick={handleEditMessage}
            marginTop={2}
            isLoading={isMessageLoading}
          >
            Submit
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditMessageModal;
