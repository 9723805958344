import axios from 'axios';

import { BACKEND_BASE_URL } from './apiHelpers';

const getUserInfo = () => {
  const requestString = `${BACKEND_BASE_URL}/user`;
  return axios.get(requestString, { withCredentials: true }).catch(error => ({
    type: 'USER_INFO_FAIL',
    error,
  }));
};

const getGameState = () => {
  const requestString = `${BACKEND_BASE_URL}/game`;
  return axios.get(requestString, { withCredentials: true }).catch(error => ({
    type: 'GAME_STATE_FAIL',
    error,
  }));
};

const getPlayers = () => {
  const requestString =  `${BACKEND_BASE_URL}/players`;
  return axios.get(requestString, { withCredentials: true }).catch(error => ({
    type: 'PLAYERS_FAIL',
    error,
  }));
}

const getPlayerState = () => {
  const requestString = `${BACKEND_BASE_URL}/player`;
  return axios.get(requestString, { withCredentials: true }).catch(error => ({
    type: 'PLAYER_STATE_FAIL',
    error,
  }));
};

const getLeaderboard = () => {
  const requestString = `${BACKEND_BASE_URL}/leaderboard`;
  return axios.get(requestString, { withCredentials: true }).catch(error => ({
    type: 'GET_LEADERBOARD_FAIL',
    error,
  }));
};

const registerUser = () => {
  const requestString = `${BACKEND_BASE_URL}/register`;
  return axios
    .post(requestString, {}, { withCredentials: true })
    .catch(error => ({
      type: 'REGISTER_USER_FAIL',
      error,
    }));
};

const registerAdmin = () => {
  const requestString = `${BACKEND_BASE_URL}/registerAsAdmin`;
  return axios
    .post(requestString, {}, { withCredentials: true })
    .catch(error => ({
      type: 'REGISTER_ADMIN_FAIL',
      error,
    }));
};

const startGame = () => {
  const requestString = `${BACKEND_BASE_URL}/startGame`;
  return axios
    .post(requestString, {}, { withCredentials: true })
    .catch(error => ({
      type: 'START_GAME_FAIL',
      error,
    }));
};

const editFreeze = (playerId, newCount) => {
  const requestString = `${BACKEND_BASE_URL}/editPlayerFreeze`;
  return axios
    .post(requestString, {playerId, newCount}, { withCredentials: true })
    .catch(error => ({
      type: 'EDIT FREEZE FAIL',
      error,
    }));
}

const editPlayerMessage = (playerId, newMessage) => {
  const requestString = `${BACKEND_BASE_URL}/editPlayerMessage`;
  return axios
    .post(requestString, { playerId, newMessage}, { withCredentials: true })
    .catch(error => ({
      type: 'EDIT MESSAGE FAIL',
      error,
    }));
}

const assassinateTarget = key => {
  const requestString = `${BACKEND_BASE_URL}/assassinate`;
  return axios
    .post(requestString, { key }, { withCredentials: true })
    .catch(error => ({
      type: 'START_GAME_FAIL',
      error,
    }));
};

const eliminate = playerId => {
  const requestString = `${BACKEND_BASE_URL}/eliminate`;
  return axios
    .post(requestString, { playerId }, { withCredentials: true })
    .catch(error => ({ type: 'ELIMINATE_FAIL', error }));
};

const endGame = () => {
  const requestString = `${BACKEND_BASE_URL}/endGameAndReset`;
  return axios
    .post(requestString, {}, { withCredentials: true })
    .catch(error => ({ type: 'END_GAME_FAIL', error }));
}

export {
  getUserInfo,
  getGameState,
  getPlayerState,
  getPlayers,
  getLeaderboard,
  registerUser,
  startGame,
  endGame,
  editFreeze, 
  editPlayerMessage,
  assassinateTarget,
  registerAdmin,
  eliminate,
};
