import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Heading,
  Text,
  Link,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  useColorMode,
} from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';

import { Card } from '../components/Card';
import { buildURI, FRONTEND_BASE_URL } from '../utils/apiHelpers';
import { getUserInfo } from '../utils/apiWrapper';

const LOGIN_SUCCESS_QUERY_PARAM = 'success';

// A custom hook that builds on useLocation to parse the query string for you.
const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

export const Login = ({ setUser }) => {
  const [showError, setShowError] = useState(false);
  const { colorMode } = useColorMode();
  const didLoginSucceed = useQuery().get(LOGIN_SUCCESS_QUERY_PARAM);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const resp = await getUserInfo();

      if (resp.status !== 200) setShowError(true);
      else {
        setShowError(false);
        setUser(resp.data.result);
      }
    };

    if (didLoginSucceed === 'true') {
      fetchUserInfo();
    }
  }, [didLoginSucceed, setUser]);

  return (
    <Box py="12" px={{ base: '4', lg: '12' }}>
      <Box maxW="lg" mx="auto">
        <Card>
          <Heading textAlign="center" size="lg" fontWeight="extrabold">
            Welcome to assassins 🥷
          </Heading>
          <Link
            href={buildURI(
              'auth/login',
              `${FRONTEND_BASE_URL}?${LOGIN_SUCCESS_QUERY_PARAM}=true`,
              `${FRONTEND_BASE_URL}?${LOGIN_SUCCESS_QUERY_PARAM}=false`
            )}
          >
            <Button colorScheme="red" width="100%" marginTop="12">
              <FaGoogle />
              <Text as="span" marginLeft="4">
                Sign in to play
              </Text>
            </Button>
          </Link>
          <Text textAlign="center" size="sm" marginTop="6">
            Use the email registered in our{' '}
            <Link
              color={colorMode === 'dark' ? 'teal.200' : 'teal.600'}
              href="https://members.h4i.app"
              isExternal
            >
              memberDB tool
            </Link>
          </Text>
        </Card>
        {showError && (
          <Alert status="error" marginTop="4">
            <AlertIcon />
            <AlertDescription>Authentication failure.</AlertDescription>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setShowError(false)}
            />
          </Alert>
        )}
      </Box>
    </Box>
  );
};
