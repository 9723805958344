const SERVICE_BASE_URL = 
  process.env.REACT_APP_VERCEL_ENV === 'production'
    ? `https://members.h4i.app/api`
    : process.env.REACT_APP_VERCEL_ENV === 'preview'
    ? `https://members.h4i.app/api`
    : 'http://localhost:9000/api';

const BACKEND_BASE_URL =
  process.env.REACT_APP_VERCEL_ENV === 'production'
    ? `https://assassins.h4i.app/api`
    : process.env.REACT_APP_VERCEL_ENV === 'preview'
    ? `https://${process.env.REACT_APP_VERCEL_URL}/api`
    : 'http://localhost:9001/api';

const FRONTEND_BASE_URL =
  process.env.REACT_APP_VERCEL_ENV === 'production'
    ? `https://assassins.h4i.app`
    : process.env.REACT_APP_VERCEL_ENV === 'preview'
    ? `https://${process.env.REACT_APP_VERCEL_URL}`
    : 'http://localhost:3000';

/**
 * Builds URL based on endpoint
 * @param {String} endpoint the endpoint for the URL
 * @param {String} successRedirect URL to redirect to on successful requests
 * @param {String} failureRedirect  URL to redirect to on failed requests
 */
const buildURI = (endpoint, successRedirect, failureRedirect = '/login') => {
  const uri = new URL(`${SERVICE_BASE_URL}/${endpoint}`);

  switch (endpoint) {
    case 'auth/login':
      uri.searchParams.append('successRedirect', successRedirect);
      uri.searchParams.append('failureRedirect', failureRedirect);
      break;
    default:
      break;
  }
  return uri;
};

export { buildURI, BACKEND_BASE_URL, FRONTEND_BASE_URL };
