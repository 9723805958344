import {
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Modal,
  Button,
  Text,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { eliminate } from '../utils/apiWrapper';

const ConfirmEliminateModal = ({ onClose, isOpen, player, playerName }) => {
  const [isEliminateLoading, setEliminateLoading] = useState(false);
  const [eliminateError, setEliminateError] = useState(false);
  const handleEliminate = async playerId => {
    setEliminateLoading(true);

    const resp = await eliminate(playerId);
    if (resp.status !== 200) {
      setEliminateError(true);
      setEliminateLoading(false);
      onClose();
    } else {
      setEliminateError(false);
      setEliminateLoading(false);
      onClose();
    }
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody display="inherit">
          <VStack alignItems="flex-start">
            <Text size="lg">This action cannot be easily reversed</Text>
            <Button
              colorScheme="red"
              size="lg"
              onClick={() => handleEliminate(player.id)}
              isLoading={isEliminateLoading}
            >
              Eliminate {playerName}
            </Button>
            {eliminateError && <Text color='red'>ERROR</Text>}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmEliminateModal;
